<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-full-mobile is-6-tablet is-4-desktop">
            <form action class="box">
              <figure class="image">
                <img
                  src="../assets/undraw_by_my_car_ttge.svg"
                  style=" height: 160px; margin: 30px 20px"
                  alt
                />
              </figure>
              <b-field>
                <b-input
                  placeholder="Username"
                  v-model="input.username"
                  size="is-medium"
                  icon="account"
                  icon-right="close-circle"
                  icon-right-clickable
                  @icon-right-click="clearIconClick"
                ></b-input>
              </b-field>
              <b-field>
                <b-input
                  v-model="input.password"
                  @keyup.enter="login"
                  icon="lock"
                  size="is-medium"
                  type="password"
                  placeholder="Password"
                  password-reveal
                ></b-input>
              </b-field>

              <b-button
                @click="login"
                :disabled="isLoading"
                type="is-primary"
                class="is-medium"
                style="margin-top: 30px"
                :expanded="true"
                :outlined="true"
                >Login</b-button
              >
              <b-loading
                :is-full-page="true"
                :active.sync="isLoading"
              ></b-loading>
              <p class="align-center flex-row justify-center has-margin-top-20">
                Don't have an account?
                <span class="has-text-primary has-margin-left-5">
                  <router-link to="/register">Sign up</router-link>
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      input: {
        username: '',
        password: ''
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['isUser', 'isMechanic'])
  },
  methods: {
    login() {
      this.isLoading = true
      this.$store
        .dispatch('loginUser', {
          username: this.input.username,
          password: this.input.password
        })
        .then(() => {
          if (this.isUser) {
            this.$router.push({ name: 'dashboard' })
          } else if (this.isMechanic) {
            this.$router.push({ name: 'carServicesList' })
          }
        })
        .catch(err => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: `Error: ${err.response.data.message}`,
            type: 'is-danger',
            position: 'is-top',
            actionText: 'Retry'
          })
        })
    },
    clearIconClick() {
      this.input.email = ''
    }
  }
}
</script>

<style scoped></style>
